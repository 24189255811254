export function jsonActionsSystemDevices(
  reboot_shutdown_restart,
  id,
  identity
) {
  //Despliegue de boton de acciones de sistema
  return [
    {
      label: "Formatear a Fábrica",
      icon: "pi pi-exclamation-triangle",
      command: () => {
        reboot_shutdown_restart(
          "wipe",
          "Formatear a Fábrica",
          "devices",
          identity
        );
      },
    },
    {
      label: "Reiniciar",
      icon: "pi pi-replay",
      command: () => {
        reboot_shutdown_restart("reboot", "Reiniciar", "devices", identity);
      },
    },
    {
      label: "Apagar",
      icon: "pi pi-power-off",
      command: () => {
        reboot_shutdown_restart("shutdown", "Apagar", "devices", identity);
      },
    },
  ];
}

export function jsonActionsContent(handleModal) {
  //Despliegue de boton de acciones de contenido
  return [
    {
      label: "Fondo de pantalla",
      icon: "pi pi-window-minimize",
      command: () => {
        handleModal("backgroundChange", true);
      },
    },
    {
      label: "Descarga de contenido",
      icon: "pi pi-download",
      command: () => {
        handleModal("contentDownload", true);
      },
    },
    {
      label: "Enviar Mensaje",
      icon: "pi pi-comment",
      command: () => {
        handleModal("message", true);
      },
    },
  ];
}

export function jsonActionsSecurityDevices(handleModal, data, deleted) {
  //Despliegue de boton de acciones de contenido
  return [
    {
      label: "Reportes",
      icon: "pi pi-chart-line",
      command: () => {
        handleModal("reports", true);
      },
    },
    {
      label: data.motive_lock === 'disabled' ? 'Activar Dispositivo' : data.motive_lock === null ? 'Desactivar Dispositivo' : '',
      icon: data.motive_lock === 'disabled' ? 'pi pi-check-circle' : data.motive_lock === null ? 'pi pi-times-circle' : '',
      disabled: data.motive_lock === 'locked',
      command: () => {
        if (data.motive_lock === null && (data.status_lock === false || data.status_lock === null || ( data.status_lock === true && data.motive_lock === null))) {
          deleted("desactivar");
        } else {
          deleted("activar");
        }
      },
    },
    {
      label: data.motive_lock === 'locked' ? 'Desbloquear Dispositivo' : (data.motive_lock === null || data.motive_lock === 'disabled') ? 'Bloquear Dispositivo' : '',
      icon: data.motive_lock === 'locked' ? 'pi pi-lock-open' : (data.motive_lock === null || data.motive_lock === 'disabled') ? 'pi pi-lock' : '',
      command: () => {
        if ((data.motive_lock === null || data.motive_lock === 'disabled')) {
          handleModal("bloquear", true);
        } else {
          handleModal("desbloquear", true);
        }
      },
    },
  ];
}

export function jsonInfoDashboard(data) {
  return [
    {
      title: "Identificador",
      value: data.identity,
      icon: "fa-solid fa-mobile",
    },
    {
      title: "Última Conexión",
      value: data.last_date,
      icon: "fa-solid fa-clock",
    },
    {
      title: "Sistema Operativo",
      value: data.so,
      icon:
        data.so === "Android" ? "fa-brands fa-android" : "fa-brands fa-windows",
    },
    {
      title: "Estado",
      value:
        data.status_lock === null || data.status_lock === false
          ? "Desbloqueado"
          : data.status_lock === "pending lock"
          ? "Pendiente de bloqueo"
          : data.status_lock === "pending unlock"
          ? "Pendiente de desbloqueo"
          : "Bloqueado",
      icon:
        data.status_lock === null || data.status_lock === false
          ? "fa-sharp fa-solid fa-unlock"
          : "fa-sharp fa-solid fa-lock",
    },
  ];
}
