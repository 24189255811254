import {
  dateDiff,
  differenceTime,
} from "../../../../components/generals/charts/utils/DatesFormats";

export default function formatConnections(connections) {
  // console.log(connections)
  // console.log(connections[0].date, new Date(connections[0].date).getTimezoneOffset())
  let today = new Date();

  let array = [
    { day: "", counter: 0, backgroundColor: "#3F9BFF" },
    { day: "", counter: 0, backgroundColor: "#3F9BFF" },
    { day: "", counter: 0, backgroundColor: "#3F9BFF" },
    { day: "", counter: 0, backgroundColor: "#3F9BFF" },
    { day: "", counter: 0, backgroundColor: "#3F9BFF" },
    { day: "", counter: 0, backgroundColor: "#3F9BFF" },
    { day: "", counter: 0, backgroundColor: "#3F9BFF" },
  ];

  let daysLabels = [];
  let arrayData = [];
  let blackgroundColorData = [];

  function days(days) {
    let date = new Date();
    date.setUTCDate(new Date().getUTCDate() - days);
    return date.getUTCDate();
  }

  let day7 = days(1);
  let day6 = days(2);
  let day5 = days(3);
  let day4 = days(4);
  let day3 = days(5);
  let day2 = days(6);
  let day1 = days(7);

  function daysArray(date, day) {
    let month = date.getUTCMonth() + 1;
    let year = date.getUTCFullYear();
    let dayArgument = day;
    if (dayArgument > date.getUTCDate()) {
      if (month === 1) {
        month = 12;
        year -= 1;
      } else {
        month -= 1;
      }
    }
    return [day, month, year];
  }

  let day7Array = daysArray(today, day7);
  let day6Array = daysArray(today, day6);
  let day5Array = daysArray(today, day5);
  let day4Array = daysArray(today, day4);
  let day3Array = daysArray(today, day3);
  let day2Array = daysArray(today, day2);
  let day1Array = daysArray(today, day1);

  function labels(array) {
    let day = array[0];
    let dayLabel = day > 9 ? day : `0` + day;
    let month = array[1];
    let monthLabel = month > 9 ? month : `0` + month;
    let year = array[2];

    return `${dayLabel}-${monthLabel}-${year}`;
  }

  if (connections !== null) {
    let firstDate;

    if (connections && connections.length !== 0) {
      connections.map((connection) => {
        let diff = dateDiff(new Date(connection.date), 7, "-");
        if (diff < 7) {
          let index = diff;
          array[index].counter += 1;
          if (firstDate === undefined) {
            firstDate = connection.date;
          } else {
            let difference = differenceTime(
              new Date(firstDate),
              new Date(connection.date)
            );
            firstDate = connection.date;
            if (difference <= 20) {
              array[index].backgroundColor = "#DC3545";
            }
          }
        }
        return diff;
      });

      array.reverse();

      array[6].day = labels(day7Array);
      array[5].day = labels(day6Array);
      array[4].day = labels(day5Array);
      array[3].day = labels(day4Array);
      array[2].day = labels(day3Array);
      array[1].day = labels(day2Array);
      array[0].day = labels(day1Array);

      array.map(({ day }) => daysLabels.push(day));

      array.map(({ counter }) => arrayData.push(counter));

      array.map(({ backgroundColor }) =>
        blackgroundColorData.push(backgroundColor)
      );

      return {
        data: arrayData,
        labels: daysLabels,
        backgroundColor: blackgroundColorData,
      };
    }
  }
}
