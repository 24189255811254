import React, { useState, useEffect } from "react";
import { Knob } from "primereact/knob";
import style from "../../../css/generals/charts/Knob.module.css";
import "../../../css/globals.css";

export default function KnobChart({ data, type, size = 100, alert }) {
  const [value, setValue] = useState(0);
  const [dataRam] = useState(data);
  const [typeChart] = useState(type);

  function porcentage(data, chart) {
    let total = 0;
    let available = 0;
    let porcentage;
    if (data !== null)
      if (chart === "general") {
        for (const ram of data.ram) {
          total += ram.total;
          available += ram.available;
        }
      } else if (chart === "individual") {
        total = data.ram.total;
        available = data.ram.available;
      }

    porcentage = Math.round((available * 100) / total);
    if (porcentage >= 0 && porcentage <= 100) setValue(100 - porcentage);
  }

  useEffect(() => {
    porcentage(dataRam, typeChart);
  }, [dataRam, typeChart]);

  return (
    <>
      <div>
        <Knob
          className={type === "individual" ? style.spacePie : ""}
          readOnly
          value={value}
          valueTemplate={value + "%"}
          size={size}
          valueColor={alert && alert < value ? "#FF3023" : "#003686"}
          rangeColor={alert && alert < value ? "#FFB5B5" : "#007AFF"}
          textColor={"black"}
          onChange={(e) => setValue(e.value)}
        />
      </div>
    </>
  );
}
