import React, { useState, useEffect } from "react";
import Doughnuts from "../../components/generals/charts/doughnut";
import Pie from "../../components/generals/charts/pie";
import BoxChart from "../../components/generals/boxCharts";
import KnobChart from "../../components/generals/charts/knob";
import Mapa from "../../components/generals/map";
import { get_data } from "../../actions/index";
import Header from "../../components/generals/header";
import { ProgressSpinner } from "primereact/progressspinner";
import styles from "../../css/Home.module.css";

export default function Home() {
  const [urlStat] = useState("stats");
  const [urlConn] = useState("connections");
  const [urlMap] = useState("locations");

  const [loadingCharts, setLoadingCharts] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);

  const [charts, setCharts] = useState(null);
  const [stats, setStats] = useState(null);
  const [map, setMap] = useState(null);

  const getDataCharts = async (conn) => {
    setLoadingCharts(true);
    let data = await get_data("home", conn);
    if (data) setCharts(data?.data);
    else setCharts([]);
    setLoadingCharts(false);
  };

  const getDataStats = async (stat) => {
    setLoadingStats(true);
    let data = await get_data("home", stat);
    if (data) setStats(data?.data);
    else setStats([]);
    setLoadingStats(false);
  };

  const getDataMap = async (loc) => {
    let data = await get_data("home", loc);
    if (data) setMap(data.data);
  };

  useEffect(() => {
    getDataStats(urlStat);
  }, [urlStat]);

  useEffect(() => {
    getDataCharts(urlConn);
  }, [urlConn]);

  useEffect(() => {
    getDataMap(urlMap);
  }, [urlMap]);

  return (
    <div className="content-wrapper">
      <div className="content">
        <div className="container-fluid">
          <Header title="Home" />
          <div className={styles.spaceCharts}>
            <BoxChart
              width="100%"
              heightBody="80%"
              height="25rem"
              title="Disco"
              icon="fa-solid fa-floppy-disk"
            >
              {loadingStats && <ProgressSpinner />}
              {stats && stats?.length !== 0 && (
                <Pie data={stats} type="general"/>
              )}
              {stats && stats?.length === 0 && <h5>Sin data a mostrar</h5>}
            </BoxChart>
            <BoxChart
              width="100%"
              heightBody="80%"
              height="25rem"
              title="Ram"
              icon="fa-solid fa-memory"
            >
              {loadingStats && <ProgressSpinner />}
              {stats && stats?.length !== 0 && (
                <KnobChart data={stats} type="general" size={210} />
              )}
              {stats && stats?.length === 0 && <h5>Sin data a mostrar</h5>}
            </BoxChart>
            <BoxChart
              width="100%"
              heightBody="80%"
              height="25rem"
              title="Conexiones de Equipos"
              icon="pi pi-tablet"
            >
              {loadingCharts && <ProgressSpinner />}
              {charts && charts?.length !== 0 && (
                <Doughnuts data={charts} />
              )}
              {charts && charts?.length === 0 && <h5>Sin data a mostrar</h5>}
            </BoxChart>
          </div>
          <div className={styles.bodyHome}>
            <BoxChart
              width="100%"
              icon="pi pi-map-marker"
              height="35rem"
              padding="1.5rem"
              title="Geoposicionamiento"
              margin="0 0 1.5rem 0"
            >
              {!map ? <ProgressSpinner /> : <Mapa info={map} />}
            </BoxChart>
          </div>
        </div>
      </div>
    </div>
  );
}
